<template>
  <WeContainer card="">
    <WeCard>
      <div class="row align-items-center">
        <!-- Title -->
        <div class="col">
          <h5 class="custom-card-title">
            <i class="fas fa-chart-bar"></i>
            <span>{{ $t("competitorPriceAnalysis") | toUpperCase }}</span>
          </h5>
        </div>
        <!-- Title -->
        <!-- Settings -->
        <div class="col-auto">
          <div class="dropdown ml-3">
            <button
              class="btn btn-light border dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
            >
              <i class="fas fa-cogs mr-2"></i> {{ $t("settings") }}
            </button>
            <div
              class="dropdown-menu p-0 dropdown-menu-right"
              style="width: 250px"
              aria-labelledby="dropdownMenuButton"
            >
              <div class="px-4 pt-2">
                <WeInput
                  class="mb-0"
                  label="Bildirim E-Postası"
                  v-on:keyup-enter="onUpdateEmail"
                  v-model="email"
                />
              </div>
              <div class="dropdown-divider"></div>
              <div class="text-center mb-2">
                <WeSubmitButton
                  v-bind:submit-status="emailSubmit"
                  v-on:submit="onUpdateEmail"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- ./Settings -->
      </div>
      <hr />

      <!-- Loading -->
      <div class="position-relative" style="min-height: 250px" v-if="loading">
        <WeLoading />
      </div>
      <!-- ./Loading -->

      <template v-else>
        <!-- Add Product -->
        <AddProduct v-bind:class="{ 'mb-3': !priceAnalysis.products.length }" />
        <!-- ./Add Product -->

        <template v-if="!priceAnalysis.products.length">
          <div class="text-center">
            <img
              src="img/background/not-found.svg"
              alt=""
              width="650"
              class="img-fluid"
              style="opacity: 0.7"
            />
          </div>
        </template>
        <div class="row mt-3" v-if="priceAnalysis.products.length">
          <div class="col-12 col-xl order-2 order-xl-1">
            <PriceTable />
          </div>
          <div
            class="col-12 col-xl-3 order-1 order-xl-2"
            v-if="priceAnalysis.products.length"
          >
            <!-- Price Statistic -->
            <PriceStatistic v-model="priceAnalysis.products" />
            <!-- ./Price Statistic -->
          </div>
        </div>
      </template>
    </WeCard>
  </WeContainer>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Push from "push.js";

const AddProduct = () => import("./views/AddProduct/Index.vue");
const PriceTable = () => import("./views/PriceTable/Index.vue");
const PriceStatistic = () => import("./views/PriceStatistic/Index.vue");

export default {
  name: "PriceAnalysis",
  sockets: {
    data: function (data) {
      this.updateProductCompetitor(data);
    },
  },
  components: {
    AddProduct,
    PriceTable,
    PriceStatistic,
    Push,
  },
  data() {
    return {
      loading: true,
      showModal: false,
      showProductModal: false,
      email: null,
      emailSubmit: false,
      products: [],
    };
  },
  methods: {
    ...mapActions("priceAnalysis", [
      "load",
      "getOpponents",
      "getProducts",
      "updateEmail",
    ]),
    toggleProductModal() {
      if (!this.showProductModal && this.productLimitExceed) return;
      this.showProductModal = !this.showProductModal;
    },
    onUpdateEmail() {
      this.emailSubmit = true;
      this.updateEmail({
        email: this.email,
        onSuccess: (result) => {
          this.$toast.success(this.$t("notificationEmailUpdated"));
          this.updateLocalEmail();
        },
        onFinish: () => {
          this.emailSubmit = false;
        },
      });
    },
    updateLocalEmail() {
      const storedSession = JSON.parse(localStorage.getItem("session"));
      storedSession.priceAnalysis.email = this.email;
      localStorage.setItem("session", JSON.stringify(storedSession));
    },
    setNotificationEmail() {
      if (this.priceConfig) {
        this.email = this.priceConfig.email;
      }
    },
    // Update method from socket
    updateProductCompetitor(data) {
      this.priceAnalysis.products = this.priceAnalysis.products.map(
        (product) => {
          if (product.id == data.productId) {
            const compIndex = product.competitors.findIndex(
              (c) => c.id == data.competitorId
            );
            if (compIndex >= 0) {
              const competitor = product.competitors[compIndex];
              competitor.price = data.price;
              competitor.currency_code = { code: data.currency };

              if (data.convertedPrice) {
                competitor.converted_price = data.price;
                competitor.converted_price_currency = data.currency;

                competitor.price = data.convertedPrice;
                competitor.currency_code.code = data.convertedCurrency;
              }

              competitor.availability = data.availability;
              competitor.updatedAt = helper.timestampToDate(data.updatedAt);
              competitor.loading = data.status == "pending";
              competitor.status = data.status;

              data.favicon = helper.getFavicon(competitor.url);
              data.url = competitor.url;
            }
          }

          return product;
        }
      );
      this.pushNotification(data);
    },
    pushNotification(data) {
      if (Push.Permission.has()) {
        let title, emote, competitorName;

        competitorName = data.competitorName;
        if (data.competitorTag) {
          competitorName += ` - ${data.competitorTag}`;
        }

        switch (data.type) {
          case "priceIncreased":
            title = "Rakip Fiyatı Yükseldi";
            emote = "📈";
            competitorName +=
              " | " + localization.getMoneyFormat(data.currency, data.price);
            break;

          case "priceDecreased":
            title = "Rakip Fiyatı Düştü";
            emote = "📉";
            competitorName +=
              " | " + localization.getMoneyFormat(data.currency, data.price);
            break;

          case "renewedOfStock":
            title = "Rakip Stok Miktarı Güncellendi";
            emote = "✅";
            break;

          case "outOfStock":
            title = "Rakip Stok Miktarı Tükendi";
            emote = "❌";
            break;

          default:
            title = null;
            break;
        }

        if (title) {
          Push.create(`${title} ${emote}`, {
            body: competitorName,
            icon: data.favicon,
            timeout: 7000,
            onClick: function () {
              window.open(data.url);
              this.close();
            },
          });
        }
      } else {
        Push.Permission.request();
      }
    },
  },
  computed: {
    ...mapState(["priceAnalysis"]),
    priceConfig() {
      let result = null;

      const stored = localStorage.getItem("session");
      if (stored) {
        const parsedStoreData = JSON.parse(stored);
        result = parsedStoreData.priceAnalysis;
      }

      return result;
    },
    productLimitExceed() {
      if (this.priceConfig) {
        return (
          this.priceAnalysis.products.length >= this.priceConfig.productLimit
        );
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.load({
      onFinish: () => {
        this.getProducts({
          onFinish: () => {
            this.loading = false;
          },
          onError: (err) => {
            if (err.status == 401) {
              this.$toast.error(this.$t("checkUsernameOrEmail"));
            }
          },
        });
        this.setNotificationEmail();
      },
    });
    if (!Push.Permission.has()) {
      Push.Permission.request();
    }
  },
};
</script>
<style lang="scss">
.chart-transition-enter,
.chart-transition-leave-to {
  transform: scale(0.9);
  opacity: 0;
}

.chart-transition-enter-active,
.chart-transition-leave-active {
  transition: transform 500ms ease-in-out, opacity 750ms ease-in-out;
}
</style>